import {
  Body2,
  Button,
  CopyCapsule,
  Link,
  List,
  ListControls,
  ListItem,
  ListItemHeader,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  ListTitle,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { fetchControllerJSON } from '../../../api/controllersApi';
import { AccessPointsWidget } from '../../../components/AccessPointsWidget';
import { ClientsWidget } from '../../../components/ClientsWidget';
import Passwords from '../../../components/LegacyWirelessNetworksWidget';
import { Nav } from '../../../components/Nav';
import { Page, PageSection } from '../../../components/Page';
import { ServiceSetsWidgetCOS } from '../../../components/ServiceSetsWidgetCOS';
import { WidgetSuspenseAndErrorBoundary } from '../../../components/WidgetSuspenseAndErrorBoundary';
import { paths } from '../../../constants';
import { ResourceNotFoundError } from '../../../errors/errors';
import { useControllerVerion } from '../../../hooks/useControllerVerion';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { styled } from '../../../stitches';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';
import { isDefinedAndNotEmpty } from '../../../utils/isDefined';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName',
});

const PageColumns = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$16',
  '@sm': {
    gridTemplateColumns: '2fr 3fr',
  },
});

const PageColumn = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
});

const PageFirstColumn = styled(PageColumn, {
  '@sm': {
    gridColumn: '1',
  },
  vStack: '$16',
  alignItems: 'stretch',
});

const PageSecondColumn = styled(PageColumn, {
  '@sm': {
    gridColumn: '2',
  },
  vStack: '$16',
  alignItems: 'stretch',
});

const ControllerMetadataWidget = ({ controllerName }: { controllerName: string }) => {
  const { data: networkInfoJSON } = useQuery(
    ['controller', controllerName, 'network-info'],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  );

  expectDefinedOrThrow(
    networkInfoJSON,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const controllerVersion = useControllerVerion(controllerName);

  return (
    <List>
      <ListItemHeader>
        <ListTitle>Metadata</ListTitle>
        <ListControls>
          <Button
            as={RouterLink}
            to={makeDrawerLink(paths.drawers.EditControllerMetadata, { controllerName })}
            variant="secondary"
            size="small"
          >
            Edit
          </Button>
        </ListControls>
      </ListItemHeader>
      <ListItemPair>
        <ListItemLabel>Customer</ListItemLabel>
        <ListItemValue>
          {isDefinedAndNotEmpty(networkInfoJSON.company_slug) ? (
            <Link
              as={RouterLink}
              to={Nav.makeTo({
                root: makeLink(paths.pages.CompaniesList, {}),
                drawer: makeLink(paths.drawers.CompanySummary, {
                  companyName: networkInfoJSON.company_slug,
                }),
              })}
            >
              {networkInfoJSON.company_slug}
            </Link>
          ) : (
            'N / A'
          )}
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Network</ListItemLabel>
        <ListItemValue>
          <CopyCapsule
            aria-label="Copy network name"
            arrangement="leading-icon"
            textValue={controllerName}
          >
            {controllerName}
          </CopyCapsule>
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Address</ListItemLabel>
        <ListItemValue>{networkInfoJSON.address} </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Square footage</ListItemLabel>
        <ListItemValue>{`${networkInfoJSON.square_feet || 'n/a'} sq ft`} </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Generation</ListItemLabel>
        <ListItemValue>{controllerVersion.versionString}</ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Patch panel diagram</ListItemLabel>
        <ListItemValue>
          {networkInfoJSON?.noc_metadata?.patch_panel_diagram_url ? (
            <Button
              as="a"
              href={networkInfoJSON.noc_metadata.patch_panel_diagram_url}
              target="_blank"
              size="small"
              variant="secondary"
            >
              Open diagram
            </Button>
          ) : (
            'n/a'
          )}
        </ListItemValue>
      </ListItemPair>

      {networkInfoJSON?.noc_metadata?.notes && (
        <ListItem style={{ flexDirection: 'column', alignItems: 'stretch' }}>
          <ListItemLabel style={{ alignSelf: 'flex-start' }}>Notes</ListItemLabel>
          <Body2 style={{ whiteSpace: 'pre-line' }}>{networkInfoJSON.noc_metadata.notes}</Body2>
        </ListItem>
      )}
    </List>
  );
};

export default function ControllerDetails() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  expectDefinedOrThrow(controllerName);

  const controllerVersion = useControllerVerion(controllerName);

  useDocumentTitle('', controllerName);

  return (
    <Page style={{ padding: 0 }}>
      <PageSection style={{ padding: 16 }}>
        <PageColumns>
          <PageFirstColumn>
            <WidgetSuspenseAndErrorBoundary title="Metadata">
              <ControllerMetadataWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
            {controllerVersion.isCOS ? (
              <WidgetSuspenseAndErrorBoundary title="Service sets">
                <ServiceSetsWidgetCOS controllerName={controllerName} />
              </WidgetSuspenseAndErrorBoundary>
            ) : (
              <WidgetSuspenseAndErrorBoundary title="Wireless networks">
                <Passwords controllerName={controllerName} />
              </WidgetSuspenseAndErrorBoundary>
            )}
          </PageFirstColumn>
          <PageSecondColumn>
            <WidgetSuspenseAndErrorBoundary title="Access points">
              <AccessPointsWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
            <WidgetSuspenseAndErrorBoundary title="Clients">
              <ClientsWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
          </PageSecondColumn>
        </PageColumns>
      </PageSection>
    </Page>
  );
}
